export const PERMISSION_ACTIONS = {
    read: {
        name: 'read'
    },
    create: {
        name: 'create'
    },
    update: {
        name: 'update'
    },
    delete: {
        name: 'delete'
    },
    recover: {
        name: 'recover'
    },
    export: {
        name: 'export'
    },
    import: {
        name: 'import'
    },
    payrollSendToPendingAuthorize: {
        name: 'payrollPendingAuthorize'
    },
    payrollSendToAuthorized: {
        name: 'payrollAuthorized'
    },
    payrollSendToUnauthorized: {
        name: 'payrollUnauthorized'
    },
    bankLayoutSendToCreated: {
        name: 'bankLayoutSendToCreated'
    },
    bankLayoutSendToCompleted: {
        name: 'bankLayoutSendToCompleted'
    },
    bankLayoutSendToGenerated: {
        name: 'bankLayoutSendToGenerated'
    },
};

export const PERMISSION_SUBJECTS = {
    home: {
        name: 'home'
    },
    calendar: {
        name: 'calendar',
        pluralLabel: 'Calendario'
    },
    notifications: {
        name: 'notifications',
        pluralLabel: 'Notificaciones',
    },
    client: {
        name: 'client',
        pluralLabel: 'Clientes',
        config: {
            import: {
                entities: [
                    {
                        label: 'Información General',
                        enum: 'CLIENTES'
                    },
                    {
                        label: 'Empresas Asociadas',
                        enum: 'CLIENTES_EMPRESAS_PAGADORAS'
                    }
                ]
            }
        }
    },
    employee: {
        name: 'employee',
        pluralLabel: 'Empleados',
        config: {
            import: {
                entities: [
                    {
                        label: 'Información General',
                        enum: 'EMPLEADOS'
                    },
                    {
                        label: 'Cuentas Bancarias',
                        enum: 'EMPLEADOS_CUENTAS_BANCARIAS'
                    },
                    {
                        label: 'Empresas Asociadas',
                        enum: 'EMPLEADOS_EMPRESAS_PAGADORAS'
                    }
                ]
            }
        }
    },
    incidents: {
        name: 'incidents',
        pluralLabel: 'Incidencias',
        config: {
            import: {
                entities: [
                    {
                        label: 'Incidencias',
                        enum: 'INCIDENCIAS'
                    },
                ]
            }
        }
    },
    vacations: {
        name: 'vacations',
        pluralLabel: 'Vacaciones'
    },
    paymentStrategies: {
        name: 'paymentStrategies',
        pluralLabel: 'Estrategias de Pago',
    },
    payroll: {
        name: 'payroll',
        pluralLabel: 'Nóminas'
    },
    bankLayouts: {
        name: 'bankLayouts',
        pluralLabel: 'Layouts Bancarios',
    },
    payrollReceipts: {
        name: 'payrollReceipts',
        pluralLabel: 'Recibos Nóminas'
    },
    company: {
        name: 'company',
        pluralLabel: 'Empresas',
        config: {
            import: {
                entities: [
                    {
                        label: 'Información General',
                        enum: 'EMPRESAS'
                    },
                    {
                        label: 'Cuentas Bancarias',
                        enum: 'EMPRESAS_CUENTAS_BANCARIAS'
                    },
                    {
                        label: 'Registros Patronales',
                        enum: 'EMPRESAS_REGISTROS_PATRONALES'
                    },
                ]
            }
        }
    },
    promoter: {
        name: 'promoter',
        pluralLabel: 'Promotores'
    },
    payrollConcept: {
        name: 'payrollConcept',
        pluralLabel: 'Conceptos'
    },
    commissionExpense: {
        name: 'commissionExpense',
        pluralLabel: 'Gastos Comisión'
    },
    user: {
        name: 'user',
        pluralLabel: 'Usuarios'
    },
    emails: {
        name: 'emails',
        pluralLabel: 'Correos Electrónicos'
    },
    expedients: {
        name: 'expedients',
        pluralLabel: 'Expedientes Digitales',
    },
    imports: {
        name: 'imports',
        pluralLabel: 'Importaciones',
    },
    alerts: {
        name: 'alerts',
        pluralLabel: 'Alertas',
    },
    products: {
        name: 'products',
        pluralLabel: 'Productos',
    },
    intervalsPeriodicities: {
        name: 'intervalsPeriodicities',
        pluralLabel: 'Intervalos Periodicidades',
    },
    fiscalParameters: {
        name: 'fiscalParameters',
        pluralLabel: 'Parámetros Fiscales',
    },
    invoices: {
        name: 'invoices',
        pluralLabel: 'Facturas',
    },
    bank: {
        name: 'bank',
        pluralLabel: 'Bancos'
    },
    clinic: {
        name: 'clinic',
        pluralLabel: 'Clínicas'
    },
    zipCode: {
        name: 'zipCode',
        pluralLabel: 'Códigos Postales'
    },
    colonie: {
        name: 'colonie',
        pluralLabel: 'Colonias'
    },
    department: {
        name: 'department',
        pluralLabel: 'Departamentos'
    },
    weekDay: {
        name: 'weekDay',
        pluralLabel: 'Días Semana',
    },
    foreignExchange: {
        name: 'foreignExchange',
        pluralLabel: 'Divisas'
    },
    state: {
        name: 'state',
        pluralLabel: 'Estados'
    },
    maritalStatus: {
        name: 'maritalStatus',
        pluralLabel: 'Estados Civiles'
    },
    genre: {
        name: 'genre',
        pluralLabel: 'Géneros',
    },
    municipality: {
        name: 'municipality',
        pluralLabel: 'Municipios'
    },
    country: {
        name: 'country',
        pluralLabel: 'Países'
    },
    periodicity: {
        name: 'periodicity',
        pluralLabel: 'Periodicidades'
    },
    job: {
        name: 'job',
        pluralLabel: 'Puestos'
    },
    paymentStrategyItem: {
        name: 'paymentStrategyItem',
        pluralLabel: 'Rubros Estrategias de Pago'
    },
    familyType: {
        name: 'familyType',
        pluralLabel: 'Tipos Familiares'
    },
    paymentType: {
        name: 'paymentType',
        pluralLabel: 'Tipos Pagos'
    },
    peopleType: {
        name: 'peopleType',
        pluralLabel: 'Tipos Personas'
    },
    productType: {
        name: 'productType',
        pluralLabel: 'Tipos Productos'
    },
};


export const arrangeImportItemsBySubject = (props = {}) => {
    const {subject} = props;
    const response = [];
    if (subject) {
        const subjectData = PERMISSION_SUBJECTS[subject];
        if (subjectData?.config?.import?.entities) {
            const entities = subjectData.config.import.entities;
            if (entities && entities.length > 0) {
                entities.forEach(entity => {
                    response.push({
                        labelSubject: entity.label,
                        enumSubject: entity.enum,
                        pluralSubjectLabel: subjectData.pluralLabel
                    })
                });
            }
        }
    }

    return response;
}
